import React from 'react'
import ('./Logo.css')

export default function Logo(props) {


  
  return (
 
    <svg id="logo" className='header-logo' width="20vh" height="10vh" viewBox="0 0 654 110" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M138.232 5.6319L123.688 55.8159L109.144 106H94.456L71.128 25.216L46.936 106L32.392 106.144L18.352 55.888L4.312 5.6319H18.28L40.168 90.736L64.36 5.6319H79.048L102.088 90.448L124.12 5.6319H138.232Z" stroke="black" strokeWidth="6"/>
    <path d="M229.9 5.6319V106H216.796V60.496H165.676V106H152.572V5.6319H165.676V49.696H216.796V5.6319H229.9Z" stroke="black" strokeWidth="6"/>
    <path d="M311.604 83.68H267.827L259.763 106H245.939L282.227 6.20789H297.347L333.492 106H319.667L311.604 83.68ZM307.859 73.024L289.715 22.336L271.571 73.024H307.859Z" stroke="black" strokeWidth="6"/>
    <path d="M411.083 5.6319V16.2879H383.723V106H370.619V16.2879H343.115V5.6319H411.083Z" stroke="black" strokeWidth="6"/>
    <path d="M496.852 5.63199C507.796 5.63199 517.252 7.69599 525.22 11.824C533.284 15.856 539.428 21.664 543.652 29.248C547.972 36.832 550.132 56.032 550.132 56.032C550.132 66.304 547.972 75.232 543.652 82.816C539.428 90.304 533.284 96.064 525.22 100.096C517.252 104.032 507.796 106 496.852 106H465.604V5.63199H496.852ZM496.852 95.2C509.812 95.2 519.7 91.792 526.516 84.976C533.332 78.064 536.74 68.416 536.74 56.032C536.74 43.552 533.284 33.808 526.372 26.8C519.556 19.792 509.716 16.288 496.852 16.288H478.708V95.2H496.852Z" stroke="black" strokeWidth="6"/>
    <path d="M600.532 106C591.22 106 582.724 103.84 575.044 99.52C567.364 95.104 561.268 89.008 556.756 81.232C552.34 73.36 550.132 64.528 550.132 54.736C550.132 44.944 552.34 36.16 556.756 28.384C561.268 20.512 567.364 14.4159 575.044 10.0959C582.724 5.6799 591.22 3.47189 600.532 3.47189C609.94 3.47189 618.484 5.6799 626.164 10.0959C633.844 14.4159 639.892 20.464 644.308 28.24C648.724 36.016 650.932 44.848 650.932 54.736C650.932 64.624 648.724 73.456 644.308 81.232C639.892 89.008 633.844 95.104 626.164 99.52C618.484 103.84 609.94 106 600.532 106ZM600.532 94.624C607.54 94.624 613.828 92.992 619.396 89.728C625.06 86.464 629.476 81.808 632.644 75.76C635.908 69.712 637.54 62.704 637.54 54.736C637.54 46.672 635.908 39.664 632.644 33.712C629.476 27.664 625.108 23.0079 619.54 19.7439C613.972 16.4799 607.636 14.8479 600.532 14.8479C593.428 14.8479 587.092 16.4799 581.524 19.7439C575.956 23.0079 571.54 27.664 568.276 33.712C565.108 39.664 563.524 46.672 563.524 54.736C563.524 62.704 565.108 69.712 568.276 75.76C571.54 81.808 575.956 86.464 581.524 89.728C587.188 92.992 593.524 94.624 600.532 94.624Z" stroke="black" strokeWidth="6"/>
    </svg>
    
    
  )
}



