import React from 'react'
import './Loading.css'

export default class extends React.Component {
  render() {
    return (
      <div className="Loading">
        <div className="Loading-container">
          <svg className="radar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 505.007 505.007">
            <path
              fill="none"
              d="M392.001 84.32l-44.686 44.686 44.686 44.687zM291.317 73.007l44.685 44.687 44.687-44.687z"
            />
            <path
              d="M380.689 73.007l-44.686 44.686 5.657 5.658 5.655 5.655 44.686-44.686v89.374l16 16V68.32l21.654-21.654a24.093 24.093 0 01-11.312-11.313l-21.654 21.654H275.317l16 16h89.372z"
              fill="#ff9300"
              className="dish"
            />
            <path
              d="M208.004 209.007c13.086 0 24.703 6.285 32 16l76-76L187.529 20.533c3.867-5.296 7.929-10.482 12.417-15.405-54.362 59.616-63.286 148.653-22.234 217.772 7.336-8.503 18.181-13.893 30.292-13.893z"
              fill="#d5d9de"
              className="dish"
            />
            <path
              d="M429.655 46.666a23.904 23.904 0 0010.348 2.341c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24c0 3.705.841 7.213 2.34 10.346a24.093 24.093 0 0011.312 11.313z"
              fill="#ffcf00"
              className="dish"
            />
            <path
              d="M247.983 248.204c.005.268.02.534.02.803 0 22.092-17.909 40-40 40s-40-17.908-40-40c0-.278.015-.552.021-.829l-30.753 176.829h141.46l-22.646-130.214-8.102-46.589z"
              fill="#888693"
            />
            <path
              d="M120.002 425.007c-8.82 0-16 7.176-16 16v24h208v-24c0-8.824-7.18-16-16-16H120.002z"
              fill="#b6b8be"
            />
            <path
              d="M80.002 465.007c-13.234 0-24 10.766-24 24h304c0-13.234-10.766-24-24-24h-256z"
              fill="#888693"
            />
            <path
              d="M48.002 489.007c-4.422 0-8 3.582-8 8s3.578 8 8 8h320c4.422 0 8-3.582 8-8s-3.578-8-8-8h-320z"
              fill="#5c546a"
            />
            <path
              d="M208.004 289.007c22.091 0 40-17.908 40-40 0-.269-.015-.535-.02-.803l8.102 46.589c25.517 12.37 52.864 18.472 80.064 18.472 44.633-.004 88.836-16.383 123.734-48.207-3.52 3.21-7.198 6.15-10.898 9.044-1.482 1.146-2.95 2.32-4.455 3.425l-.004.003-128.523-128.523 25.656-25.656-5.657-5.658-44.686-44.686-16-16-52.284-52.285c-3.094-3.098-7.438-4.852-11.789-4.715-4.375.105-8.383 1.926-11.297 5.121-4.489 4.922-8.551 10.109-12.417 15.405l128.474 128.474-76 76c-7.298-9.715-18.914-16-32-16-12.111 0-22.956 5.39-30.291 13.893a39.824 39.824 0 00-9.688 25.278c-.006.277-.021.551-.021.829 0 22.092 17.908 40 40 40zm16-40c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.836 7.163-16 16-16s16 7.164 16 16z"
              fill="#b6b8be"
              className="dish"
            />
            <circle cx="208.004" cy="249.007" r="16" fill="#5c546a" />
            <path
              d="M347.315 129.006l-5.655-5.655-25.656 25.656 128.523 128.524.004-.003c1.504-1.105 2.972-2.279 4.455-3.425 3.7-2.895 7.378-5.834 10.898-9.044 3.203-2.918 5.023-6.938 5.117-11.313a16.168 16.168 0 00-4.711-11.762l-52.289-52.29-16-16-44.686-44.688z"
              fill="#888693"
              className="dish"
            />
          </svg>
        </div>
      </div>
    )
  }
}
