import React from 'react'
import './NotFoundPage.css'

export default function NotFoundPage(){
  return (
    <div className='NotFoundPage'>
      <div className='container'>
        <h2>Oops! page not found</h2>
        <h1>404 </h1>
        <p>We can't find the  page you're looking for.</p>
      </div> 
    </div>
    
  )
}