import React from 'react'
import './LoadingLogin.css'

export default class LoadingLogin extends React.Component {
  render() {
    return (
      <div className="LoadingLogin">
        <div className="lock-container">
          <svg
            className="lock-svg"
            xmlns="http://www.w3.org/2000/svg"
            width="512"
            height="512"
            viewBox="0 0 512 512"
          >
            <path
              fill="rgb(78, 78, 78)"
              d="M387 215.988V131C387 58.766 328.234 0 256 0c-72.234 0-131 58.766-131 131v84.667c0 .103.013.202.015.304-28.602 31.457-46.057 73.222-46.057 118.987C78.959 432.58 158.379 512 256 512s177.041-79.42 177.041-177.042c0-45.756-17.448-87.514-46.041-118.97zM256 30c55.691 0 101 45.308 101 101v58.639c-28.665-19.984-63.485-31.722-101-31.722s-72.335 11.739-101 31.722V131c0-55.692 45.308-101 101-101zm0 452c-81.079 0-147.042-65.962-147.042-147.042S174.921 187.917 256 187.917s147.041 65.962 147.041 147.042S337.079 482 256 482z"
            />
            <path
              fill="black"
              className="lock-combo"
              d="M256 217.917c-64.537 0-117.042 52.504-117.042 117.042S191.463 452 256 452s117.042-52.504 117.042-117.042S320.537 217.917 256 217.917zM334.959 350h6.779c-2.247 12.845-7.32 24.73-14.552 34.99l-4.776-4.776c-5.857-5.858-15.355-5.858-21.213 0-5.858 5.858-5.858 15.355 0 21.213l4.765 4.765a86.581 86.581 0 01-35.003 14.517v-6.792c0-8.284-6.716-15-15-15s-15 6.716-15 15v6.778a86.581 86.581 0 01-34.989-14.552l4.776-4.776c5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.858-21.213 0l-4.765 4.765a86.567 86.567 0 01-14.517-35.003h6.792c8.284 0 15-6.716 15-15s-6.716-15-15-15h-6.778c2.247-12.845 7.32-24.73 14.552-34.99l4.776 4.776c2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.464 10.606-4.394c5.858-5.858 5.858-15.355 0-21.213l-4.765-4.765a86.566 86.566 0 0135.004-14.517V256c0 8.284 6.716 15 15 15s15-6.716 15-15v-6.779c12.845 2.247 24.73 7.32 34.99 14.552l-4.776 4.776c-5.858 5.858-5.857 15.355 0 21.213 2.929 2.929 6.768 4.393 10.606 4.393s7.678-1.465 10.606-4.394l4.765-4.766a86.567 86.567 0 0114.517 35.003h-6.792c-8.284 0-15 6.716-15 15S326.675 350 334.959 350z"
            />
            <path
              fill="gold"
              d="M256 289.958c-24.813 0-45 20.187-45 45s20.187 45 45 45 45-20.187 45-45-20.186-45-45-45zm0 60c-8.271 0-15-6.729-15-15s6.729-15 15-15 15 6.729 15 15-6.728 15-15 15z"
            />
          </svg>
        </div>
      </div>
    )
  }
}
