import config from '../config'

const TokenService = {
  saveAuthToken(token){
    window.sessionStorage.setItem(config.TOKEN_KEY, token)
  },
  getAuthToken(){
    return window.sessionStorage.getItem(config.TOKEN_KEY)
  },
  clearAuthToken(){
    window.sessionStorage.clear(config.TOKEN_KEY)
  },
  hasAuthToken(){
    return !!this.getAuthToken()
  }
}

export default TokenService