import React from 'react'

import './Footer.css'

export default class Footer extends React.Component{

  render(){
    return(
      <footer className='footer'>
      <p>What Do | 2019  @ abcj</p>
          
     
      </footer>
    )
  }
}